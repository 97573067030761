<template>
  <CHeader fixed with-subheader light>

    <CHeaderNav class="mr-auto container-fluid">
      <CHeaderNavItem class="px-2">
        <CHeaderNavLink :to="{name: 'Home'}" active-class="font-weight-bold text-primary">
          <i class="fas fa-home mr-1"></i> หน้าหลัก
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-2">
        <CHeaderNavLink :to="{name: 'PlayLotto'}" active-class="font-weight-bold text-primary">
          <i class="fa-light fa-diamonds-4 mr-1"></i> แทงหวย
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-2">
        <CHeaderNavLink :to="{name: 'Lottosets'}" active-class="font-weight-bold text-primary">
          <i class="fas fa-layer-group mr-1"></i> หวยชุด
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-2">
        <CHeaderNavLink :to="{name: 'Game'}" active-class="font-weight-bold text-primary">
          <i class="fa-solid fa-gamepad-modern mr-1"></i> เกม
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-2">
        <CHeaderNavLink :to="{name: 'Tickets'}" active-class="font-weight-bold text-primary">
          <i class="fas fa-list mr-1"></i> รายการแทง
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-2">
        <CHeaderNavLink :to="{name: 'Report'}" active-class="font-weight-bold text-primary">
          <i class="far fa-credit-card mr-1"></i> บัญชีการเงิน
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-2">
        <CHeaderNavLink :to="{name: 'Results'}" active-class="font-weight-bold text-primary">
          <i class="fas fa-award mr-1"></i> ตรวจรางวัล
        </CHeaderNavLink>
      </CHeaderNavItem>
      <!-- <CHeaderNavItem class="px-2">
        <CHeaderNavLink :to="{name: 'Rule'}" active-class="font-weight-bold text-primary">
          <i class="fas fa-book-reader mr-1"></i> กฏกติกา
        </CHeaderNavLink>
      </CHeaderNavItem> -->
      <!-- <CHeaderNavItem class="px-2">
        <CHeaderNavLink :to="{name: 'Howto'}" active-class="font-weight-bold text-primary">
          <i class="far fa-question-circle mr-1"></i> วิธีเล่นหวย
        </CHeaderNavLink>
      </CHeaderNavItem> -->
      <CHeaderNavItem class="px-2">
        <CHeaderNavLink :to="{name: 'Help'}" active-class="font-weight-bold text-primary">
          <i class="fas fa-book mr-1"></i> คู่มือการใช้งาน
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-2">
        <CHeaderNavLink :to="{name: 'Links'}" active-class="font-weight-bold text-primary">
          <i class="fas fa-link mr-1"></i> ลิงค์ดูผล
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>

  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt
  }
}
</script>
