<template>
  <div>
    <div class="c-app" v-if="isLoggedIn">
      <TheSidebar/>
      <CWrapper>
        <TheHeader/>
        <div class="c-body">
          <main class="c-main pt-3">
            <CContainer fluid>
              <transition name="fade" mode="out-in">
                <router-view></router-view>
              </transition>
            </CContainer>
          </main>
        </div>
        <!-- <TheFooter/> -->
      </CWrapper>
    </div>
    <div v-else class="check-login">
      กำลังตรวจสอบการเข้าสู่ระบบ....
    </div>

    <AlertMessage />
  </div>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'

import AuthService from '@/services/authService'
import cAlert from '@/helpers/alert'
import Auth from '@/helpers/auth'

import AlertMessage from '@/views/components/AlertMessage'

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    AlertMessage
  },
  data() {
    return {
      isLoggedIn: false
    }
  },
  beforeCreate() {
    // this.$store.dispatch('reloadProfile')
    // this.$store.dispatch('reloadMarkets')
    // this.$store.dispatch('reloadMarketGroups')

    AuthService.getProfile()
    .then((response) => {
      if(response.success) {
        this.isLoggedIn = true
        this.$store.commit('updateProfile', response.data)
        this.$store.dispatch('reloadMarkets')
        this.$store.dispatch('reloadMarketGroups')
      }else{
        throw new Error(response?.message || ( response?.data ? JSON.stringify(response.data) : null ))
      }
    })
    .catch((e)=>{
      cAlert({
        ...e,
        title: 'กรุณาเข้าสู่ระบบใหม่อีกครั้ง',
        text: e?.message,
        icon: 'error',
        confirmButtonText: 'OK'
      }, ()=>{
        Auth.logout()
        this.$router.push({name: 'Login'})
      })
    })
  },
  mounted() {
    const viewport = document.querySelector("meta[name=viewport]")
    viewport.setAttribute('content', 'width=1400,initial-scale=0.1')
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.check-login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
